$enable-rounded: false;

$body-bg: rgba(115,115,115,1);

$font-family-sans-serif: 'Lato', sans-serif;
$font-family-base: $font-family-sans-serif;

$navbar-border-radius: 0px;

$brand-primary: $wp-color-green;


$gray:                      #55595c;
$gray-dark:                 #7c7c7c;
$gray-light:                lighten($gray,20%);
$gray-lighter:              lighten($gray-light,17%);
$gray-lightest:             lighten($gray-lighter,17%);

$text-muted:                $gray-lighter;

$link-color:                 $wp-color-green;
$link-decoration:            none;
$link-hover-color:           darken($wp-color-green,20%);
$link-hover-decoration:      underline;

$navbar-light-color:                $gray-dark;
$navbar-light-hover-color:          $link-color;
$navbar-light-active-color:         $link-color;
$navbar-light-disabled-color:       rgba(0,0,0,.15);

$navbar-padding-vertical:           0.9rem;

$font-size-root:             16px;

$grid-gutter-width: 50px;


$dropdown-bg:                    rgba(255,255,255,0.70);
$dropdown-link-color:            $gray-dark;
$dropdown-link-hover-color:      darken($gray-dark, 5%);
$dropdown-link-hover-bg:         #f5f5f5;

$headings-color: #303030;

