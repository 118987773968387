MAIN {
	background-color: $wp-main-background-color;
	padding-top: 6.4rem;
	padding-bottom: 3rem;

	.wpPageMainContainer {
		position: relative;
		z-index: 10;
	}

	.wpPageMainContentContainer > *:first-child {
	      margin-top: 0;
	}
	.wpPageMainContentContainer > *:last-child {
		margin-bottom: 0;
	}

	.wpContentGrid {
/*		margin-top: 1.5rem;
		margin-bottom: 1.5rem;*/

/*		& > DIV >  *:first-child {
			margin-top: 0;
		}
		& > DIV >  *:last-child {
			margin-bottom: 0;
		}*/
	}
	.wpContentElement {
		/*margin-bottom: 1.5rem;*/
		display: block;
	}
}