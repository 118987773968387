$wp-bg-greylight: rgba(233,233,233,1);
$wp-bg-greymid: rgba(154,154,154,1);
$wp-bg-greydark: rgba(115,115,115,1);
$wp-bg-greyultradark: rgba(81,83,77,1);

$wp-fonts-white: rgba(255,255,255,1);
$wp-fonts-greyultralight: rgba(154,154,154,1);
$wp-fonts-greylight: rgba(218,218,218,1);
$wp-fonts-greymid: rgba(81,83,77,1);

$wp-color-green: rgba(120,184,42,1);
$wp-color-silver: rgba(171,190,199,1);

$wp-nav-active: rgba(61,61,61,1);
$wp-nav-hover: rgba(73,73,73,1);

$wp-bg-input: rgba(242,242,242,1);