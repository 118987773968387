FOOTER {
	background-color: $wp-bg-greydark;

	.wpPageFooterContainer {
		padding: 1rem 0 1.5rem 0;

		background-image:url('../Img/bgWatermarkFooter.png');
		background-position: 0 0;
		background-repeat: no-repeat;

		@include media-breakpoint-up(md) {
			padding-top: 2rem;
			padding-bottom: 5rem;
		}

		.wpPageFooterImprint {
			color: $wp-footer-color;
			text-align: center;

			@include media-breakpoint-up(md) {
				text-align: left;
			}
			.wpLogoFooter {
				width: 76px;
				height: auto;
				margin-bottom: 10px;
			}
		}
		.wpPageFooterPartner {
			color: $wp-footer-color;
			text-align: left;
			margin-top: 25px;

			@include media-breakpoint-up(md) {
				float: right;
				margin-top: 0;
			}
			.wpPageFooterPartnerTitle {
				width: 100%;
				border-bottom: 1px solid $wp-footer-color;
				padding-bottom: 8px;
				margin-bottom: 12px;
				font-weight: bold;
			}

			UL {
				LI {
					float: left;
					width: 100%;

					A {
						float: left;
						color: $wp-footer-color;
						margin: 3px 0;
						min-height: 30px;
						font-style: italic;

						IMG {
							width: 50px;
							height: 36px;
							margin-right: 12px;
						}
					}
				}
			}
		}
	}
}