.ceImageWithText {
	position: relative;

	IMG {
		width:100%;
		height: auto;
	}

	.ceImageWithTextText {
		color: #fff;
		background-color: $wp-color-green;
		width: 100%;
		padding: 2rem;

		H2, H3 {
			color: #fff;
		}

		@include media-breakpoint-up(md) {
			position: absolute;
			bottom: 2rem;
			left: 2rem;
			width: 50%;
			background: none;
			padding: 0;
			text-shadow: 1px 1px 6px #333;
		}
	}
}