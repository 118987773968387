H2 {
	color: $wp-color-green;
	text-transform: uppercase;
	font-family: 'Dosis', sans-serif;
	font-weight: 400;
	font-size: 26px;
	margin: 0;
	@include media-breakpoint-up(sm) {
		font-size: 32px;
	}
	@include media-breakpoint-up(md) {
		font-size: 56px;
	}
}
H3 {
	color: $wp-color-green;
	font-family: 'Dosis', sans-serif;
	font-weight: 400;
	font-size: 20px;
	margin: 0;

	@include media-breakpoint-up(sm) {
		font-size: 22px;
	}
	@include media-breakpoint-up(md) {
		font-size: 33px;
	}
}

.bgGreen {
	background-color: $wp-color-green;
	color: $wp-fonts-white;

	H2 {
		color: $wp-fonts-white;
	}
	H3 {
		color: $wp-fonts-white;
	}
}
.bgGreyLight {
	background-color: $wp-bg-greylight;
	color: $wp-fonts-greymid;

	H2 {
		color: $wp-color-green;
	}
	H3 {
		color: $wp-bg-greydark;
	}
}
.bgGreyMid {
	background-color: $wp-bg-greymid;
	color: $wp-fonts-white;

	H2 {
		color: $wp-fonts-white;
	}
	H3 {
		color: $wp-fonts-white;
	}
}
.bgGreyDark {
	background-color: $wp-bg-greydark;
	color: $wp-fonts-white;

	H2 {
		color: $wp-fonts-white;
	}
	H3 {
		color: $wp-fonts-white;
	}
}
.bgGreyUltradark {
	background-color: $wp-bg-greyultradark;
	color: $wp-fonts-white;

	H2 {
		color: $wp-fonts-white;
	}
	H3 {
		color: $wp-fonts-white;
	}
}

.rte {
	text-align: center;

	UL {
		list-style-type: none;

		LI {
			&:before {
				content:'\f111';
				font-family: "Font Awesome 5 Pro";
				font-size: 10px;
				padding-right: 6px;
				vertical-align: middle;
			}
		}
	}
}
.wpContentGrid {
	padding-top: 2rem;
	padding-bottom: 2rem;

	.rte {
		text-align: left;
	}
}



/*Formular*/
FORM.form-horizontal {
	.control-label {
		text-align: right;
	}
	INPUT,
	TEXTAREA {
		background-color: $wp-bg-input;
		border-radius: 3px;
	}
	INPUT.btn-primary {
		float: right;
		background-color: $wp-color-green;
	}
}


/* Desaturation */
IMG.desaturate {
	filter: grayscale(100%); /* Standard */
	-webkit-filter: grayscale(100%); /* Webkit */
	filter: gray;  /* IE6-9 */
	-webkit-filter: grayscale(1); /* Old WebKit */
	transition: all 0.2s ease-in;
}
IMG.desaturate:hover {
	filter: grayscale(0%);
	filter: none;
	-webkit-filter: grayscale(0); /* Old WebKit */
	transition: all 0.2s ease-in;
}

.tx-bwrk-onepage>SECTION {
	float: left;
	width: 100%;
}
.btn.btn-primary {
	border-radius: 3px;
}