HEADER {
	background-color: #fff;

	.container {
		position: relative;
	}

	.wpWatermarkHeader {
		position: absolute;
		top: 4px;
		left: 0;
		z-index: 0;
	}

	.wpLangNav {
		position: relative;
		z-index: 1;
		height: 110px;
		padding: 43px 0 0 0;

		UL {
			list-style-type: none;
			margin: 0;
			padding: 0;
			float: right;

			LI {
				float: left;
				padding: 0 6px 0 5px;
				border-right: 1px solid $wp-fonts-greylight;

				&:first-child {
					padding: 0 6px 0 0;
				}
				&:last-child {
					border-right: 0 none;
					padding: 0 0 0 5px;
				}

				A {
					float: left;
				}
			}
		}
	}
}






