.wpContentElementAccordion {
  .wpContentElementAccordionHeader {
    H4 {
      A {
        display: block;

        @include hover-focus-active {
          text-decoration: none;
        }

        I {
          padding-right: 0.25rem;

          &.show-uncollapsed {
            display: none;
          }
        }

        &.collapsed {
          I {
            &.show-uncollapsed {
              display: inline;
            }
            &.show-collapsed {
              display: none;
            }
          }
        }

      }
    }
  }
}