.ceWine {
	float: left;
	width: 100%;
	overflow: hidden;

	.wpPhotoSwipeImage {
		margin: 0;
		padding: 0;
	}

	&:hover,
	&:focus {

	}

	.wpWineCol {
		position: relative;
		overflow: hidden;

		IMG {
			width: 100%;
			height: auto;
			transition: all 0.3s ease-in;

			&:hover,
			&:focus {
				transform: scale(1.1);
				transition: all 0.3s ease-in;
			}
		}
		.wpWineSizer {
			IMG {
				display: none;
			}
			@include media-breakpoint-up(lg) {
				IMG {
					display: block;
				}
			}
		}
		.wpWineBox {
			padding: 1rem 1rem 3.5rem 1rem;
			width:100%;
			text-align: justify;
			font-size: 14px;

			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				left: 0;
				padding: 1rem;
			}

			H3 {
				font-size: 24px;
				font-weight: 600;
				text-align: center;
				margin-bottom: 6px;
			}
			H4 {
				font-size: 18px;
				text-align: center;
				margin-bottom: 12px;
			}
			.wpWineBoxText {
				line-height: 20px;
			}
			.wpWineBoxVol {
				padding-top: 6px;
				text-align: center;
				font-weight: 600;
			}
		}
		.wpWineLink {
			position: absolute;
			bottom: 0.5rem;
			right: 0.5rem;

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}

		&:hover,
		&:focus {
			.wpWineLink {
				@include media-breakpoint-up(lg) {
					display: block;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.wpWineCol {
			float: left;
			width: 50%;
		}
	}
	@include media-breakpoint-up(lg) {
		&:nth-child(even) {
			.wpWineCol {
				&:first-child{
					float: right;
				}
			}
		}
	}
	@include media-breakpoint-up(xl) {
		width: 50%;

		&:nth-child(even) {
			.wpWineCol {
				&:first-child{
					float: left;
				}
			}
		}
		&:nth-child(4n+3) {
			.wpWineCol {
				&:first-child{
					float: right !important;
				}
			}
		}
		&:nth-child(4n+4) {
			.wpWineCol {
				&:first-child{
					float: right !important;
				}
			}
		}
	}
}

