$fa-font-path:   "../FontAwesome";

//Site Specific
$wp-footer-color: $wp-fonts-greylight;
$wp-footer-color-active: $wp-color-green;

$wp-main-background-color: #FFF;

$wp-nav-background: $brand-primary;
$wp-nav-color: #FFF;
$wp-nav-hover-color: rgba(255,255,255,1);
$wp-nav-hover-background: $brand-primary;
$wp-nav-active-color:  #FFF;
$wp-nav-active-background: rgba(255,255,255,0.2);

$wp-nav-dropdown-color: $gray-light;
$wp-nav-dropdown-hover-color: rgba(255,255,255,1);
$wp-nav-dropdown-hover-background: $link-color;
$wp-nav-dropdown-active-color: $link-color;
$wp-nav-dropdown-active-background: transparent;
