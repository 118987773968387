BODY.htmlarea-content-body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.btn-white-outline {
  @include button-outline-variant(#FFF);

  @include hover-focus-active {
    color: #000;
  }
}

.btn-black {
  @include button-variant(#FFF, #000, #000);
}

.btn-facebook {
  @include button-variant(#FFF, #3b5998, #3b5998);
}

.btn-facebook-outline {
  @include button-outline-variant(#3b5998);
}

.img-fluid-center {
  margin: 0 auto;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb0half {
  margin-bottom: 0.5rem !important;
}

.mb1 {
  margin-bottom: 1rem !important;
}

.align-justify {
  text-align: justify;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}