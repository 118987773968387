.wpGallery {
	float: left;
	width: 100%;
	position: relative;
	@include media-breakpoint-up(sm) {
		width: 50%;
	}
	overflow: hidden;

	IMG {
		width: 100%;
		height: auto;
		display: none;
		transition: all 0.3s ease-in;

		&.ImageNr0 {
			display: block;
			&:hover,
			&:focus {
				transform: scale(1.1);
				transition: all 0.3s ease-in;
			}
		}

	}
	.wpGalleryOverlay {
		padding: 2rem 1rem;
		width: 100%;
		text-align: center;
		background-color: rgba(51,51,51,0.95);
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 35%;
		}

		H4 {
			font-family: 'Dosis', sans-serif;
			color: $wp-color-green;
			font-size: 28px;
		}
		H5, SPAN {
			color: $wp-fonts-white;
			font-size: 18px;
		}
	}
}
