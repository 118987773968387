.ceText {
	padding: 2rem;

	.ceTextH2Special {
		margin-bottom: 2rem;
	}
	.ceTextH3Special {
		margin-top: -2.3rem;
		margin-bottom: 2rem;
	}
}

.wpContentGrid {
	.ceText {
		padding: 0;
	}
}

.ceText.ceImprint {
	display: none;
}