.ceTeam {
	float: left;
	width: 100%;
	overflow: hidden;
	background-color: rgba(154, 154, 154, 1);

	.wpTeamCol {
		float: left;
		width: 100%;

		@include media-breakpoint-up(lg) {
			width: 50%;
		}

		IMG {
			width: 100%;
			height: auto;
		}

		.wpTeamMember {
			float: left;
			width: 100%;

			background-color: $wp-color-green;
			color: $wp-fonts-white;

			H2 {
				color: $wp-fonts-white;
			}
			H3 {
				color: $wp-fonts-white;
			}



			&:nth-child(odd) {
				background-color: $wp-fonts-white;
				color: $wp-color-green;

				H2 {
					color: $wp-color-green;
				}
				H3 {
					color: $wp-color-green;
				}
				.wpTeamMemberCol {
					float: right;
				}
			}

			.wpTeamMemberCol {
				float: left;
				position: relative;
				width: 100%;

				@include media-breakpoint-up(md) {
					width: 50%;
				}

				.wpTeamSizer {
					display: none;
					float: left;
					width: 100%;

					IMG {
						width: 100%;
						height: auto;
					}

					@include media-breakpoint-up(md) {
						display: block;
					}
				}

				.wpTeamBoxName {
					top: 33%;
					font-size: 26px;
					font-weight: bold;
					width: 100%;
					padding: 1rem;
					text-align: center;

					@include media-breakpoint-up(md) {
						position: absolute;
					}
					@include media-breakpoint-up(lg) {
						font-size: 34px;
					}
				}
				.wpTeamBoxInfo {
					top: 22%;
					font-size: 14px;

					@include media-breakpoint-up(md) {
						display: none;
						position: absolute;
					}
					width: 100%;
					padding: 1rem;
					text-align: center;
				}

				&:hover,
				&:focus {
					@include media-breakpoint-up(md) {
						.wpTeamBoxName {
							display: none;
						}
						.wpTeamBoxInfo {
							display: block;
						}
					}
				}
				.wpTeamMemberImage {
					float: left;
					width: 50%;

					IMG {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}