.wpContentSlider {
  background: $wp-main-background-color;

  .owl-carousel .owl-item img{
    height: auto;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    background: lighten($brand-primary, 30%);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: $brand-primary;
  }
}