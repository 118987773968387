.wpTabs {
	.nav-tabs {
		padding: 0 !important;
		margin: 0 !important;
		border: 0 none !important;

		.nav-item {
			padding: 0 !important;
			margin: 0 !important;
			background-position: center center;
			background-size: cover;

			@include media-breakpoint-down(md) {
				width: 100%;
			}

			A.nav-link {
				border: 0 none !important;
				text-align: center;
				position: relative;


				@include media-breakpoint-up(lg) {
					&:after {
						font-family: "Font Awesome 5 Pro";
						content: '\f13a';
						position: absolute;
						bottom: 1rem;
						left: 0;
						right: 0;
						text-align: center;
						color: $wp-fonts-white;
						font-size: 26px;
					}
				}
				@include media-breakpoint-down(md) {
					padding: 2rem 0.5rem;
				}
				IMG {
					display: none;
					width: 100%;
					height: auto;

					@include media-breakpoint-up(lg) {
						display: block;
					}
				}

				SPAN {
					font-size: 26px;
					font-family: 'Dosis', sans-serif;
					color: #fff;

					@include media-breakpoint-up(lg) {
						position: absolute;
						top: 35%;
						right: 0;
						bottom: 0;
						left: 0;
					}
				}

				&:hover,
				&:focus,
				&.active {
					background-color: rgba(120,184,42,0.75);

					SPAN,
					&:after {
						color: #000;
					}
				}
			}
		}
	}
}