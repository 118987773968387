NAV.navbar {
	background-color: $wp-color-green;
	border-top: 3px solid $wp-color-silver;
	border-bottom: 3px solid $wp-color-silver;

	.wpPageNavContainer {
		position: relative;
		padding: 0;

		.navbar-brand {
			float: left;
			margin-bottom: 0;
			position: relative;
			z-index: 300;

			@include media-breakpoint-up(xl) {
				float: right;
			}
		}

		.navbar-toggler {
			margin-top: 0px;
			border: 0 none;
			background: none;

			&.btn-white-outline:focus,
			&.btn-white-outline:active,
			&.btn-white-outline:hover {
				I {
					color: $wp-nav-hover;
				}
			}

			I {
				font-size: 45px;
			}
		}

		.wpPageNavCollapse {
			float: left;
			margin-top: 1.5rem;

			@include media-breakpoint-up(xl) {
				margin-top: 0rem;
				display: block;
			}

			UL.nav {
				position: relative;
				z-index: 200;

				@include media-breakpoint-up(xl) {
					text-align: left;
					padding-top: 12px;
				}

				LI.nav-item {
					display: block;
					float: none;
					margin-left: 0;
					margin-top: 0.3rem;
					text-transform: uppercase;

					@include media-breakpoint-up(xl) {
						display: inline-block;
						float: none;
						margin-left: 1.5rem;
						margin-top: 0rem;
					}

					.nav-link {
						display: block;

						font-size: 1.2rem;
						font-weight: 500;

						color: $wp-nav-color;

						padding: 0.425rem 1rem;

						@include hover-focus-active {
							background-color: $wp-nav-hover-color !important;
							color: $wp-nav-hover-background !important;
						}

						@include hover-focus-active {
							color: $wp-nav-hover !important;
						}
						&.active {
							color: $wp-nav-active !important;
							background-color: $wp-nav-active-background;
						}

						@include media-breakpoint-up(xl) {
							display: inline-block;
							float: none;

							@include hover-focus-active {
								color: $wp-nav-hover !important;
								background: none !important;
							}
							&.active {
								background: none !important;
							}
						}

						I {
							padding-left: 0.6rem;
						}

						&.dropdown-toggle::after {
							display: none;
						}
					}

					.dropdown-menu {
						border-radius: 0px;
						background-color: rgba(255, 255, 255, 0.98);
						min-width: auto;

						position: static;
						width: 100%;
						border: none;
						color: #FFF;

					 	padding: 0.3rem 0.65rem 0.3rem 0.65rem;
						margin: 0.25rem 0rem 0.25rem 0rem;

						clear: both;
						float: none;

						@include media-breakpoint-up(xl) {
							position: absolute;
							width: auto;
							padding: 0;
							margin: 0;
							border: 1px solid $gray-lighter;
						}


						.dropdown-divider {
							color: $gray;
						}

						.dropdown-item {
							padding: 0;

							A.dropdown-link {
								padding: 0.5rem 1rem;
								display: block;
								font-size: 0.9rem;
								color: $wp-nav-dropdown-color;

								@include hover-focus-active {
									text-decoration: none;
									background-color: $wp-nav-dropdown-hover-background;
									color: $wp-nav-dropdown-hover-color;
								}

								&.active {
									background-color: $wp-nav-dropdown-active-background;
									color: $wp-nav-dropdown-active-color;
								}

								I {
									padding-right: 0.25rem;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			.wpPageNavCollapse {
				width: 100%;

				UL.nav {
					width: 100%;

					LI {
						width: 100%;

						A {
							width: 100%;
							text-align: center;
						}
					}
				}
			}
		}

	}
}




.sticky{
	&.is-sticky {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
	}
}



NAV.tx-bwrk-onepage-menu {
	display: none !important;
}